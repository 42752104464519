/*	   typography   	*/
$text-font: 'hk_grotesk', sans-serif;

$fs-minimal: 10px;
$fs-small: 12px;
$fs-text: 14px;
$lh-text: 1.5;
$fs-medium: 16px;
$fs-semi-strong: 18px;
$fs-strong: 20px;
$fs-bold: 24px;
$fs-title: 32px;
$lh-title: (42px/32px);
/*	   end  typography   	*/

/*	   colors   	*/
$text-color: #002743;
$link-color: #3DC3E6;
$placeholder-color: #7C7C7C;
$green-color: #0B580B;
$red-color01: #E63D3D;
$red-color02: #C30C0C;
$red-color03: #B90C0C;
$red-color04: #580B0B;
$white-color: #fff;
$progress-indicator-color: #BDBDBD;
$gray-color-main: #7C888C;
$gray-color01: #64737B;
$gray-color02: #636C6F;
$gray-color03: #485A64;
$gray-color04: #5A616D;
$gray-color05: #707070;
$gray-color06: #F5F5F5;
$light-gray-color: #F5F5F7;
$border-color01: #EDF0F5;
$border-color02: #E8E8E8;
$border-color03: #F9F7F7;
$border-color04: #E7E7E7;
$border-color05: #CECECE;
/*	   end  colors   	*/

/*	   backgrounds   	*/
$body-bg: #fff;
$body-bg-blue: #F6F9FE;
$form-field-bg: #F5F5F6;
$light-blue-bg01: #F8FEFF;
$light-blue-bg02: #F3FDFF;
$light-blue-bg03: #E9F1F3;
$light-blue-bg04: #F7FAFB;
$gray-bg: #EDEDED;
$light-gray-bg: #F9F9F9;
$overlay-bg: rgba(#999999, .8);
/*	   end  backgrounds   	*/

/*	   shadows   	*/
$main-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
$shadow01: 0 4px 8px rgba(33, 33, 33, 0.1);
/*	   end  shadows   	*/