@font-face {
	font-family: 'hk_grotesk';
	src: url('../fonts/hkgrotesk-regular.woff2') format('woff2'),
	url('../fonts/hkgrotesk-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'hk_grotesk';
	src: url('../fonts/hkgrotesk-italic.woff2') format('woff2'),
	url('../fonts/hkgrotesk-italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'hk_grotesk';
	src: url('../fonts/hkgrotesk-medium.woff2') format('woff2'),
	url('../fonts/hkgrotesk-medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'hk_grotesk';
	src: url('../fonts/hkgrotesk-semibold.woff2') format('woff2'),
	url('../fonts/hkgrotesk-semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'hk_grotesk';
	src: url('../fonts/hkgrotesk-bold.woff2') format('woff2'),
	url('../fonts/hkgrotesk-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'hk_grotesk';
	src: url('../fonts/hkgrotesk-black.woff2') format('woff2'),
	url('../fonts/hkgrotesk-black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}