@import 'variables.scss';

/*	   header   	*/
.sign-up-header {
	max-width: 1328px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 60px 24px;
	box-sizing: border-box;
	margin: 0 auto;

	.basic-logo {
		width: 90px;
		transition: opacity .1s ease-in-out;

		img {
			display: block;
			width: 100%;
			height: auto;
			image-rendering: auto;
		}
		&:hover {
			opacity: .7;
		}
	}
	.user-header {
		position: relative;
		margin: 0 0 0 auto;

		&.open .dropdown-box {
			opacity: 1;
			visibility: visible;
		}
	}
	.user-name {
		display: flex;
		align-items: center;
		column-gap: 15px;
		font-size: $fs-medium;
		font-weight: 600;
		color: $text-color;
		cursor: pointer;

		&:after {
			content: '';
			width: 12px;
			height: 6px;
			background: url(../images/arrow-down-icon-gray.svg) center no-repeat;
			background-size: contain;
			margin: 0 0 -4px;
		}
	}
	.dropdown-box {
		width: 130px;
		top: 100%;
		right: 0;
	}
	.logout-link {
		display: flex;
		align-items: center;
		column-gap: 4px;
		font-size: $fs-medium;
		font-weight: 400;
		color: $red-color02;

		&:before {
			content: '';
			width: 16px;
			min-width: 16px;
			height: 16px;
			background: url(../images/menu-logout-icon.svg) center no-repeat;
			background-size: contain;
			margin: 0 0 -4px;
		}
	}
}
.main-header {
	height: 72px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 30px;
	padding: 0 60px 0 0;
	margin: 0 0 30px;
	position: relative;

	&:after {
		content: '';
		width: 100vw;
		height: 1px;
		background-color: $border-color01;
		position: absolute;
		bottom: 0;
		left: -30px;
	}
	.basic-button {
		margin: 0;
	}
}
.home-start-header {
	height: auto;
	margin: 0;

	&:after {
		display: none;
	}
}
.actions-header {
	order: 3;
	position: relative;

	&.open .dropdown-box {
		top: calc(100% + 10px);
	}
	.dropdown-box {
		width: 200px;
		top: -200px;
		left: 50%;
		transform: translateX(-50%);
		opacity: 1;
		visibility: visible;
		transition: top .7s ease-in-out;
	}
}
.burger-btn {
	width: 32px;
	height: 32px;
	display: none;
	background: url(../images/burger-icon.svg) center no-repeat;
	background-size: contain;
	cursor: pointer;
}
#check-notification {
	display: none;

	&:checked ~ {

		.notification-header {
			right: 0;
		}
		.basic-overlay {
			opacity: 1;
			visibility: visible;
		}
	}
}
.notification-btn {
	order: 2;
	width: 40px;
	min-width: 40px;
	height: 40px;
	display: none;
	justify-content: center;
	align-items: center;
	font-size: $fs-minimal;
	font-weight: 700;
	color: $link-color;
	background: $link-color url(../images/notification-icon-white.svg) center no-repeat;
	box-shadow: $main-shadow;
	border-radius: 50%;
	cursor: pointer;
	margin: 0 0 0 auto;

	&.btn-visible {
		display: flex;
	}
}
.notification-header {
	max-width: 400px;
	width: 100%;
	max-height: 100%;
	background-color: $white-color;
	overflow-y: auto;
	position: fixed;
	top: 0;
	right: -400px;
	z-index: 6;
	padding: 40px 30px 16px;
	box-sizing: border-box;
	transition: all .5s ease-in-out;

	.close-button {
		position: absolute;
		top: 20px;
		right: 20px;
	}
	h2 {
		text-align: left;
	}
	.notification-hold {
		font-size: $fs-small;
		background-color: #F6FDFF;
		box-shadow: $main-shadow;
		border-radius: 4px;
		padding: 18px 16px;
		margin: 0 0 24px;
		position: relative;
	}
	.status {
		position: absolute;
		top: 0;
		left: 0;
	}
	.balance-row {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		column-gap: 10px;
		padding: 0 0 10px;
	}
	.balance-value {
		font-size: $fs-strong;
		font-weight: 600;
	}
	.request-value {
		font-weight: 500;

		b {
			font-weight: 700;
		}
	}
	.details-date {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		row-gap: 10px;
		white-space: nowrap;
	}
	.date {
		font-size: $fs-minimal;
		color: $gray-color01;
	}
	.bottom-row {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		column-gap: 10px;
		color: $gray-color01;
		border-top: 1px solid #F4F4F4;
		padding: 10px 0 0;

		span:last-of-type {
			text-align: right;
		}
		b {
			color: $gray-color03;
		}
	}
	.notification-actions {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		column-gap: 10px;
		row-gap: 10px;
		padding-top: 6px;

		.basic-button {
			min-width: 120px;
		}
	}
	+ .basic-overlay {
		z-index: 5;
	}
}