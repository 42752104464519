@import 'variables.scss';

html, body {
    height: 100%;
    margin: 0;
}
html * {
    max-height: 999999px;
}
body {
    min-width: 320px;
    font-size: $fs-text;
    line-height: $lh-text;
    font-family: $text-font;
    color: $text-color;
    background: $body-bg;
    margin: 0;
    -webkit-text-size-adjust: 100%;

    &.blue-bg {
        background-color: $body-bg-blue;
    }
}


/*	   general reset   	*/
* {
    scroll-behavior: smooth;
}
form, fieldset {
    margin: 0;
    padding: 0;
    border-style: none;
}
img {
    border-style: none;
    vertical-align: top;
}
ul li {
    vertical-align: top;
}
input, select, textarea {
    vertical-align: middle;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
figure {
    margin: 0;
}
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}


/*	   wrapper   	*/
#wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
dl {
    margin: 0;

    dd {
        font-size: $fs-medium;
        font-weight: 600;
        color: $gray-color03;
        margin: 0;
    }
}
ol {
    margin: 0 0 20px;

    ol {
        list-style: lower-latin;
        margin: 0;
    }
}
.circle-list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    li:before {
        content: '';
        width: 3px;
        height: 3px;
        display: inline-block;
        vertical-align: middle;
        background-color: $text-color;
        border-radius: 50%;
        margin: 0 7px 0 0;
    }
}
p {
    margin: 0 0 20px;
}
h1,
h2,
h3,
.strong-title,
.medium-title {
    display: block;
    font-size: $fs-title;
    line-height: $lh-title;
    font-weight: 700;
    text-align: center;
    margin: 0 0 30px;

    i {
        font-weight: 400;
        font-style: normal;
    }
    mark {
        color: $link-color;
        background: none;
    }
    small {
        font-weight: 400;
        margin-top: 10px;

        b {
            display: block;
            font-weight: 700;
        }
    }
}
h3,
.medium-title {
    font-size: $fs-medium;
    line-height: $lh-text;
    text-align: left;
    margin: 0 0 10px;

    &.with-toggle {
        display: flex;
        align-items: flex-start;
        column-gap: 4px;
        font-weight: 500;
    }
    .toggle-hold {
        margin: 2px 0 0;
    }
}
.strong-title {
    font-size: $fs-strong;
    line-height: $lh-text;
    text-align: left;
    margin: 0 0 16px;
}
h1 {

    a {
        color: #979797;
    }
    .cash-balance {
        font-size: $fs-text;
        padding: 8px 16px;
        margin: 4px 0 0 2px;
    }
}
strong {
    font-weight: 700;
}
b {
    font-weight: 600;
}
a {
    font-weight: 600;
    color: $link-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        outline: 0 none;
    }
}
small {
    display: block;
    font-size: $fs-small;
}
/*	   footer   	*/
.sign-up-footer {
    background-color: $light-blue-bg01;

    .footer-hold {
        max-width: 1100px;
        display: flex;
        align-items: center;
        column-gap: 70px;
        padding: 36px 24px;
        margin: 0 auto;
    }
    h2 {
        max-width: 370px;
        text-align: left;
        margin: 0;
    }
    .footer-boxes {
        width: 100%;
        display: flex;
        align-items: flex-start;
        column-gap: 8px;
        text-align: center;
    }
    .box {
        max-width: 280px;
        width: 100%;
        border: 1px solid #BDBDBD;
        border-radius: 16px;
        padding: 15px;
        box-sizing: border-box;
        position: relative;
        z-index: 1;
    }
    .circle-list {
        align-items: center;
    }
}
/*	   main   	*/
.sign-up-main {
    max-width: 1328px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 24px 40px;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;

    .agree-text {
        margin: -12px 0 32px;
    }
    .basic-button {
        height: 48px;
    }
    .bottom-action {
        display: block;
        text-align: center;
        margin: 0;
    }
    .forgot-password-link {
        margin: -16px 0 32px auto;
    }
    h1 + p {
        margin: -20px 0 30px;
    }
    p {
        text-align: center;
    }
    .letter-icon {
        margin: 0 0 15px;
    }
    .help-alert {
        max-width: 240px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 5px;
        font-weight: 500;
        color: $gray-color05;
        background-color: #F7F9FA;
        border-radius: 8px;
        padding: 15px;
        box-sizing: border-box;
        position: absolute;
        top: 88px;
        right: 24px;
    }
    .business-type-addition {
        opacity: 0;
        visibility: hidden;
        max-width: 280px;
        width: 100%;
        background-color: $light-blue-bg02;
        border-radius: 16px;
        padding: 15px 30px;
        box-sizing: border-box;
        position: absolute;
        top: 32px;
        right: calc(100% + 140px);
        transition: all .1s ease-in-out;

        h3 {
            text-align: left;
        }
    }
    #limited-liability-company {
        display: none;

        &:checked ~ {

            .radio-buttons label[for="limited-liability-company"] {
                background-color: $light-blue-bg02;
                border-color: $link-color;

                &:after {
                    opacity: 1;
                }
            }
            .limited-liability-company-addition {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    #sole-proprietorship {
        display: none;

        &:checked ~ {

            .radio-buttons label[for="sole-proprietorship"] {
                background-color: $light-blue-bg02;
                border-color: $link-color;

                &:after {
                    opacity: 1;
                }
            }
            .sole-proprietorship-addition {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .back-link {
        position: absolute;
        top: 100px;
        left: 180px;
    }
}
.important-text {
    display: block;
    font-size: $fs-small;
    background-color: $light-blue-bg02;
    border-radius: 8px;
    padding: 16px 24px;
    box-sizing: border-box;
    margin: 0 0 20px;
}
.back-link {
    display: inline-flex;
    align-items: center;
    column-gap: 7px;
    font-weight: normal;
    color: $link-color;
    margin: 0 0 20px;
    cursor: pointer;

    &:before {
        content: '';
        width: 16px;
        min-width: 16px;
        height: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../images/arrow-left-icon-blue.svg);
        border: 1px solid $link-color;
        border-radius: 50%;
        box-sizing: border-box;
        margin: 0 0 -2px;
    }
}
.form-hold {
    max-width: 440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .basic-button {
        width: 100%;
    }
    .radio-buttons li {
        width: calc(50% - 8px);
    }
}
.sign-up-letter {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .basic-button {
        min-width: 180px;
    }
    p a {
        color: $text-color;
    }
}
.review-form {
    max-width: 708px;
    width: 100%;
    margin: 0 auto;

    .basic-button {
        max-width: 300px;
        width: 100%;
        display: flex;
        margin: 24px auto 20px;
    }
}
.review-hold {
    background: #FAFCFF;
    box-shadow: $main-shadow;
    border-radius: 8px;
    margin: 0 0 12px;

    &.open .title-review:after {
        transform: rotate(180deg);
    }
    .title-review {
        display: flex;
        align-items: center;
        column-gap: 35px;
        font-size: $fs-strong;
        font-weight: 500;
        padding: 35px 65px 35px 35px;
        cursor: pointer;

        &:before {
            content: '';
            width: 24px;
            min-width: 24px;
            height: 24px;
            background: #297823 url(../images/check-icon.svg) center no-repeat;
            border-radius: 50%;
            margin: 0 0 -4px;
        }
        &:after {
            content: '';
            width: 12px;
            min-width: 12px;
            height: 6px;
            background: url(../images/arrow-down-icon-gray.svg) center no-repeat;
            background-size: contain;
            margin: 0 0 -6px auto;
            transition: transform .2s ease-in-out;
        }
    }
}
.review-drop-hold {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 16px;
    row-gap: 16px;
    padding: 0 24px 24px 94px;

    dl {
        width: calc((100% - 32px)/3);
        margin: 0;
    }
    dt {
        font-size: $fs-small;
        color: $gray-color01;
        margin: 0 0 6px;
    }
}
.preview-link {
    display: inline-flex;
    align-items: center;
    column-gap: 5px;
    margin-top: 6px;

    &:before {
        content: '';
        width: 12px;
        min-width: 12px;
        height: 12px;
        background: url(../images/clip-icon.svg) center no-repeat;
        background-size: contain;
        margin: 0 0 -2px;
    }
}
.main-box {
    display: flex;
    align-items: flex-start;
}
.content {
    width: calc(100% - 200px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0 90px 30px 30px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}
.home-box {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
    margin: 0 0 16px;

    .box {
        display: flex;
        flex-direction: column;
        background-color: $body-bg;
        border-radius: 4px;
        padding: 20px 30px 0;
        box-sizing: border-box;
        position: relative;
    }
    .cash-balance-box {
        width: calc(56% - 8px);
        min-height: 350px;
    }
    .recent-transactions-box {
        width: calc(44% - 8px);
        min-height: 350px;

        table td {

            &:nth-of-type(2) {
                text-align: center;
            }
            &:last-of-type {
                text-align: right;
            }
        }
    }
    .cash-inflow-box,
    .cash-outflow-box {
        width: calc(50% - 8px);
        min-height: 170px;

        .balance-value {
            color: #3C8F1F;
        }
        table td:last-child {
            font-size: $fs-medium;
            font-weight: 700;
            text-align: right;
        }
        .absent-hold {

            .absent-icon {
                width: 14px;
                height: 20px;
                margin: 0 auto;
            }
            p {
                margin: 0;
            }
        }
    }
    .cash-outflow-box .balance-value {
        color: #8F1F1F;
    }
    .cash-title {
        display: block;

        small {
            font-weight: 400;
            color: $gray-color02;
        }
    }
    .title-with-interval {
        display: flex;
        align-items: center;
        column-gap: 6px;

        .time-interval {
            position: relative;
            top: 0;
            right: 0;
        }
    }
    .balance-value {
        display: block;
        font-size: $fs-medium;
        margin: 0 0 10px;

        b {
            font-size: 26px;
        }
    }
    .progress-text {
        display: flex;
        align-items: center;
        column-gap: 6px;
        font-size: $fs-minimal;
        font-weight: 500;
        margin: 0 0 24px;

        &:before {
            content: '';
            background: url(../images/progress-icon.svg) center no-repeat;
            background-size: contain;
            width: 12px;
            min-width: 12px;
            height: 12px;
            margin: 0 0 -2px;
        }
    }
    .chart-img {
        text-align: center;

        img {
            max-width: 100%;
        }
        &.absent {
            margin: auto 0 40px;
        }
    }
    table td {

        &:first-of-type {
            padding-left: 0;
        }
        &:last-of-type {
            padding-right: 0;
        }
    }
    .more-link {
        padding: 20px 0;
        margin-top: auto;
    }
    .inflow-outflow-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 6px;
        font-size: $fs-small;

        li {
            display: flex;
            align-items: center;
            column-gap: 6px;

            &:not(:last-child):after {
                content: '';
                width: 12px;
                min-width: 12px;
                height: 12px;
                background: url(../images/arrow-right-icon.svg) center no-repeat;
                background-size: contain;
                margin: 0 0 -2px;
            }
        }
    }
    &.account-wrap .cash-balance-box {
        width: calc(50% - 8px);
    }
    .spent-box {
        width: calc(50% - 8px);
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        text-align: center;
        padding: 0 !important;

        .cell {
            width: 50%;
            height: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-width: 0 1px 1px 0;
            border-style: solid;
            border-color: #D1F6FF;
            padding: 16px;
            box-sizing: border-box;

            &:nth-child(2n) {
                border-right: none;
            }
            &:nth-last-child(1),
            &:nth-last-child(2) {
                border-bottom: none;
            }
            &:nth-child(4n + 2) .spent-icon {
                background-color: #FFF2DF;
            }
            &:nth-child(4n + 3) .spent-icon {
                background-color: #E4DFFF;
            }
            &:nth-child(4n) .spent-icon {
                background-color: #DFFFFB;
            }
        }
        .spent-icon {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #DFFFF0;
            border-radius: 50%;
            margin: 0 0 10px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .title-spent {
            display: block;
            font-weight: 500;
            margin: 0 0 6px;
        }
        .balance-value {
            line-height: 36px;
            margin: 0;
        }
    }
    .account-box {
        width: calc(50% - 8px);

        .download-link {
            column-gap: 4px;
            font-size: $fs-small;

            &:before {
                width: 12px;
                min-width: 12px;
                height: 12px;
            }
        }
    }
    .account-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 24px;
        row-gap: 14px;
        margin: 0 0 24px;

        .strong-title {
            display: flex;
            align-items: center;
            column-gap: 4px;
            margin: 0;

            a {
                width: 10px;
                min-width: 10px;
                height: 10px;
                background: url(../images/edit-icon-solid.svg) center no-repeat;
                margin: 0 0 -6px;
            }
        }
    }
    .account-actions {
        display: flex;
        align-items: center;
        column-gap: 25px;
    }
    .account-details-hold {
        padding: 30px;
        margin: 0;
    }
    .footer-account {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 70px;

        .share-btn,
        .download-link {
            padding: 20px 0;
        }
    }
    + .title-top {
        margin-top: 14px;
    }
}
.absent-hold {
    color: $text-color;
    text-align: center;
    margin: auto 0;

    .absent-icon {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 10px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .medium-title {
        font-weight: 600;
        text-align: center;
    }
    p {
        line-height: 32px;
        margin: 0 0 15px;
    }
    .basic-button {
        margin: 0;
    }
}
.time-interval {
    position: absolute;
    top: 12px;
    right: 12px;

    &.open .dropdown-box {
        opacity: 1;
        visibility: visible;
    }
    .interval-btn {
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 6px;
        font-size: $fs-small;
        font-weight: 500;
        background-color: $light-gray-color;
        border-radius: 6px;
        padding: 0 12px;
        cursor: pointer;

        &:after {
            content: '';
            width: 8px;
            min-width: 8px;
            height: 4px;
            background: url(../images/arrow-down-icon-gray.svg) center no-repeat;
            background-size: contain;
            margin: 0 0 -4px;
        }
    }
    .dropdown-box {
        width: 150px;
        top: calc(100% + 10px);
        right: 0;
    }
}
.home-start {
    max-width: 740px;
    width: 100%;
    padding: 70px 0 48px 60px;
    margin: 0 auto;
}
.status-box {
    text-align: center;
    background-color: $body-bg;
    border-radius: 8px;
    padding: 50px;
    margin: 0 0 40px;
    position: relative;

    .status {
        height: 32px;
        font-size: $fs-text;
        border-radius: 16px;
        position: absolute;
        top: 24px;
        right: 24px;
        padding: 0 14px;
    }
    .status-img {
        width: 52px;
        margin: 0 auto 16px;

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    .strong-title {
        text-align: center;
    }
    small {
        max-width: 390px;
        margin: 0 auto;
    }
}
.faq-box {
    background-color: $body-bg;
    border-radius: 8px;

    .faq-hold {
        border-bottom: 1px solid $body-bg-blue;

        &.open .question:after {
            transform: rotate(180deg);
        }
    }
    .question {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        font-size: $fs-medium;
        font-weight: 500;
        padding: 16px 34px;
        cursor: pointer;

        &:after {
            content: '';
            width: 10px;
            min-width: 10px;
            height: 7px;
            background: url(../images/arrow-down-icon-gray.svg) center no-repeat;
            background-size: contain;
            transition: transform .1s ease-in-out;
        }
    }
    .answer {
        color: $gray-color02;
        padding: 0 34px;
    }
}
.title-top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    row-gap: 10px;
    margin: 0 0 24px;

    h1 {
        font-size: 28px;
        text-align: left;
        margin: 0;
    }
    .title-buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 20px;
        row-gap: 10px;

        .basic-overlay {
            z-index: 4;
        }
    }
    .basic-button {
        margin: 0;
    }
}
.details-box {
    max-width: 430px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    color: $text-color;
    background-color: $white-color;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: -430px;
    bottom: 0;
    z-index: 6;
    transition: right .5s ease-in-out;

    + .basic-overlay {
        z-index: 5;
    }
    .details-body {
        overflow-y: auto;
    }
    .close-button {
        top: 16px;
        right: 16px;
    }
    .strong-title {
        font-weight: 500;
        margin: 0 0 10px;
    }
    .details-value {
        display: flex;
        align-items: center;
        column-gap: 8px;
        font-size: 40px;
        line-height: 1.3;
        margin: 0 0 6px;
    }
    .date {
        display: block;
        color: $gray-color01;
        margin: 0 0 15px;
    }
    hr {
        margin: 0 0 32px;
    }
    dl {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        line-height: 1;
        margin: 0 0 32px;

        dt {
            color: $gray-color01;
        }
    }
    .attachment-title {
        display: block;
        font-size: $fs-semi-strong;
        font-weight: 600;
        margin: 0 0 5px;
    }
    small {
        color: $gray-color04;
        margin: 0 0 15px;

        a {
            font-size: $fs-text;
            margin-left: 10px;
        }
        + .basic-button {
            min-height: 40px;
            margin-top: 15px;
        }
    }
    .basic-button + a {
        text-align: center;
    }
    .download-button {
        display: flex;
        align-items: center;
        column-gap: 4px;
        font-weight: 500;

        &:before {
            content: '';
            width: 20px;
            min-width: 20px;
            height: 20px;
            background: url(../images/export-icon.svg) center no-repeat;
            background-size: contain;
            margin: 0 0 -2px;
        }
    }
    .details-footer {
        height: 64px;
        min-height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 70px;
        background: $light-blue-bg01;
        border: 1px solid $border-color03;
        box-shadow: $main-shadow;
        box-sizing: border-box;
        margin: auto -30px 0;
    }
    .file-label-details {
        display: flex;
        align-items: center;
        column-gap: 10px;
        background-color: $light-gray-bg;
        border-radius: 4px;
        padding: 16px 24px;
        box-sizing: border-box;
        margin: 0 0 10px;

        .file-text {
            color: $gray-color02;
        }
        .file-icon {
            width: 20px;
            min-width: 20px;
            height: 20px;
            background: url(../images/export-icon.svg) center no-repeat;
            background-size: contain;
        }
        .file-btn {
            font-weight: 500;
            color: $red-color03;
            background: none;
            padding: 0;
            margin: 0 0 0 auto;
        }
    }
    &.outflow-details {
        max-width: 950px;
        right: -950px;

        .strong-title {
            display: flex;
            align-items: center;
            column-gap: 10px;
            margin: 0 0 30px;

            .status {
                margin: 0 0 -6px;
            }
        }
    }
    .details-header {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        row-gap: 16px;
        column-gap: 100px;
        margin: 0 0 16px;

        .date {
            margin: 0 auto 0 0;
        }
        dl {
            margin: 0;
        }
    }
    .bulk-payment-header dl {
        display: block;
        margin: 0;
    }
    .table-hold {
        min-height: 224px;
        overflow-y: auto;
        margin: 0 0 20px;
    }
}
hr {
    min-height: 1px;
    background-color: #E3E4E4;
    border: none;
    margin: 0 0 24px;
}
.payment-content {
    padding-bottom: 72px;

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    textarea,
    select,
    .select-btn {
        background: $body-bg;
    }
    .radio-buttons {
        width: 100%;
        flex-direction: column;
        row-gap: 12px;
        margin: 0 0 24px;

        li {
            width: 100%;
            max-width: 100%;
        }
        label {
            height: 66px;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 2px;
            border-width: 2px;
            border-style: solid;
            border-color: transparent;
            border-radius: 8px;
            padding: 0 12px 0 36px;

            &:after {
                top: 20px;
                right: 25px;
            }
        }
        .medium-title {
            font-size: $fs-semi-strong;
            margin: 0;
        }
        .footer-payment {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 10px;
            font-size: $fs-small;
        }
        .cost {
            font-weight: 400;

            b {
                font-weight: 600;
            }
        }
        .coming-soon {
            top: -2px;
            right: -2px;
        }
    }
}
.time-hold {
    display: flex;
    align-items: center;
    font-size: $fs-small;
    color: $gray-color05;
    column-gap: 4px;

    &:before {
        content: '';
        width: 12px;
        min-width: 12px;
        height: 12px;
        background: url(../images/time-icon.svg) center no-repeat;
        background-size: contain;
        margin: 0 0 -2px;
    }
}
.title-payment {
    display: block;
    font-size: $fs-semi-strong;
    font-weight: 500;
    text-align: center;
    margin: 0 0 30px;
}
.payment-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;

    .back-link {
        margin: 0;
    }
    .basic-button {
        width: auto;
        margin: 0 0 0 auto;
    }
}
.review-box {
    width: 100%;
    background-color: $body-bg;
    border-radius: 8px;
    padding: 24px 24px 0;
    margin: 0 0 12px;

    .medium-title {
        margin: 0 0 24px;
    }
    .review-lists {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        column-gap: 10px;
    }
    dl {
        width: 60%;
        box-sizing: border-box;
        margin: 0 0 32px;

        &:nth-of-type(2n) {
            width: calc(40% - 10px);
        }
        dt {
            color: $gray-color01;
            margin: 0 0 6px;
        }
    }
}
.payment-letter {
    display: flex;
    flex-direction: column;
    align-items: center;

    .time-hold {
        margin: 0 0 20px;
    }
    .download-link {
        margin: 0 0 40px;
    }
}
.settings-box {
    max-width: 550px;
    width: 100%;
    background-color: $body-bg;
    border-radius: 8px;
    padding: 40px 56px 50px;
    box-sizing: border-box;

    > form {
        display: flex;
        flex-direction: column;
    }
    .basic-button {
        margin: 0 0 0 auto;
    }
    hr {
        margin: 8px 0 32px;
    }
    .medium-title {
        margin: 0 0 15px;
    }
    .directors-list {
        font-weight: 500;
        color: rgba($text-color, .5);
        border: 1px solid $border-color02;
        border-radius: 4px;

        li {
            border-bottom: 1px solid $border-color02;
            padding: 17px 20px;

            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}
.business-details-box {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 10px;
    column-gap: 10px;

    .important-text {
        max-width: 310px;
        width: 100%;
        padding: 24px;
        margin: 0;
    }
}
.integrations-box {
    display: flex;
    flex-wrap: wrap;
    column-gap: 28px;
    row-gap: 28px;

    .box {
        width: calc((100% - 84px)/4);
        min-height: 188px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: $body-bg;
        padding: 28px 28px 8px;
        box-sizing: border-box;
        position: relative;
    }
    .status {
        position: absolute;
        top: 8px;
        right: 8px;

        &.coming-soon {
            top: 0;
            right: 0;
        }
    }
    .integration-title {
        display: flex;
        align-items: center;
        column-gap: 16px;
        margin: 0 0 16px;
    }
    .integration-logo {
        width: 40px;
        min-width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .pesapal-logo {
        width: 94px;
    }
    .medium-title {
        font-size: $fs-semi-strong;
        margin: 0;
    }
    p {
        margin: 0 0 8px;
    }
    .integration-actions {
        margin-top: auto;
        position: relative;

        &.open .dropdown-box {
            top: calc(100% + 10px);

        }
        .basic-button {
            height: 28px;
            font-size: $fs-small;
            padding: 0 15px;
            margin: 0;
        }
        .dropdown-box {
            width: 200px;
            opacity: 1;
            visibility: visible;
            top: -720px;
            left: 50%;
            transform: translateX(-50%);
            transition: top .7s ease-in-out;
        }
    }
}
.security-box {
    display: flex;
    align-items: flex-start;
    column-gap: 25px;
    row-gap: 20px;

    .settings-box {
        max-width: 100%;
        padding: 30px 40px 40px;

        &:first-child {
            width: calc(44% - 12.5px);
        }
        &:last-child {
            width: calc(56% - 12.5px);
            padding-bottom: 0;
        }
    }
    .strong-title {
        margin: 0 0 4px;
    }
    p {
        color: $gray-color04;
    }
    .table-hold {
        margin: 0 -40px;
    }
}
.team-tabs {
    display: flex;
    background-color: $body-bg;
    border-radius: 8px;

    input[type="radio"] {
        display: none;
    }
    #owner:checked ~ {

        .tabs-menu-col label[for="owner"] {
            font-weight: 700;
            color: $link-color;
        }
        .tabs-content-col .owner-tab {
            display: block;
        }
    }
    #accountant:checked ~ {

        .tabs-menu-col label[for="accountant"] {
            font-weight: 700;
            color: $link-color;
        }
        .tabs-content-col .accountant-tab {
            display: block;
        }
    }
    .tabs-menu-col {
        width: 174px;
        border-right: 1px solid $border-color02;
        padding: 24px 40px;
        box-sizing: border-box;

        .strong-title {
            font-weight: 500;
            margin: 0 0 30px;
        }
    }
    .team-tabs-menu {
        display: flex;
        flex-direction: column;
        column-gap: 24px;
        row-gap: 24px;
        font-size: 17px;
        color: $gray-color04;

        label {
            cursor: pointer;
        }
    }
    .tabs-content-col {
        width: calc(100% - 174px);
        padding: 24px 60px;
        box-sizing: border-box;

        .strong-title {
            font-size: $fs-bold;
            margin: 0 0 8px;
        }
        p {
            color: $gray-color04;
        }
    }
    .owner-tab,
    .accountant-tab {
        display: none;
    }
}
.roles-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 60px;
    row-gap: 60px;

    .box {
        max-width: 280px;
        width: 100%;
        background-color: #F5FFFA;
        border-radius: 8px;
        overflow: hidden;
        
        &:nth-child(2n) {
            background-color: #FFF5F5;
        }
    }
    .medium-title {
        font-size: $fs-semi-strong;
        font-weight: 600;
        border-bottom: 1px solid #A0B9A6;
        padding: 12px 30px;
        margin: 0;
    }
    .role-body {
        min-height: 320px;
        display: flex;
        flex-direction: column;
        padding: 20px 30px;
        box-sizing: border-box;
    }
    p {
        text-align: center;
        margin: auto;
    }
    ul {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
}
.send-money-hold {
    position: relative;

    &.open .dropdown-box {
        opacity: 1;
        visibility: visible;
    }
    .dropdown-box {
        width: 200px;
        top: calc(100% + 10px);
        right: 0;
    }
}
.bulk-payments-review {
    max-width: 860px;
    width: 100%;
    margin: 0 auto;

    .table-hold {
        margin: 0 0 24px;
    }
    .bulk-payment-header {
        justify-content: space-between;

        dl {
            width: auto;
        }
    }
}
.bulk-payment-header {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 14px;
    row-gap: 32px;
    background-color: $body-bg;
    box-shadow: $main-shadow;
    border-radius: 8px;
    padding: 32px;
    margin: 0 0 20px;

    dl {
        width: calc((100% - 52px)/4);

        dt {
            opacity: .7;
            font-weight: 500;
            margin: 0 0 4px;
        }
        dd {
            display: flex;
            align-items: center;
            font-size: $fs-bold;
            font-weight: 700;
            color: $text-color;
        }
    }
    .preview-link {
        font-size: $fs-small;
        margin: 0;
    }
    .status {
        margin-top: 10px;
    }
}
.help-box {
    width: 160px;
    font-weight: 500;
    color: $white-color;
    background-color: #35345F;
    border-radius: 30px;
    box-sizing: border-box;
    position: fixed;
    z-index: 4;
    right: 16px;
    bottom: 16px;

    &.open {

        > ul {
            max-height: 100px;
            opacity: 1;
            visibility: visible;
            padding-top: 17px;
        }
        .help-btn:after {
            transform: rotate(-90deg);
        }
    }
    .help-btn {
        display: flex;
        align-items: center;
        column-gap: 6px;
        cursor: pointer;
        padding: 17px 20px;

        &:before {
            content: '';
            width: 18px;
            min-width: 18px;
            height: 18px;
            background: url(../images/help-icon.svg) center no-repeat;
            background-size: contain;
            margin: 0 0 -2px;
        }
        &:after {
            content: '';
            width: 5px;
            min-width: 5px;
            height: 8px;
            background: url(../images/arrow-right-icon-white.svg) center no-repeat;
            background-size: contain;
            margin: 0 0 -4px auto;
            transition: transform .1s ease-in-out;
        }
    }
    > ul {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        padding: 0 20px;
        transition: all .2s ease-in-out;

        a,
        label {
            font-weight: 500;
            color: $white-color;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    #faq-check {
        display: none;

        &:checked ~ {

            .faq-modal {
                right: 0;
            }
            .basic-overlay {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.faq-modal {
    max-width: 1000px;
    width: 100%;
    color: $text-color;
    background-color: $body-bg;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: -1000px;
    bottom: 0;
    padding: 40px 24px;
    box-sizing: border-box;
    opacity: 1;
    visibility: visible;
    transition: right .5s ease-in-out;
    z-index: 33;

    .close-button {
        top: 16px;
        right: 16px;
    }
    .search-hold {
        max-width: 340px;
        margin: 0 auto 45px;

        input[type="text"] {
            border: 1px solid $border-color05;
        }
    }
    .faq-box {
        display: flex;
        column-gap: 30px;
        row-gap: 20px;
        background: none;
        border-radius: 0;
    }
    .faq-menu {
        width: 154px;
        min-width: 154px;

        ul {
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            position: sticky;
            top: 0;
        }
        a {
            display: flex;
            align-items: center;
            font-weight: 500;
            color: $gray-color-main;
            border-right: 2px solid transparent;
            padding-right: 24px;
            transition: all .2s ease-in-out;

            &:hover {
                color: $link-color;
                text-decoration: none;
            }
            &.current {
                font-weight: 700;
                color: $link-color;
                border-right-color: $link-color;
                cursor: default;
            }
        }
    }
    .faq-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        ul {
            list-style: disc;
            padding: revert;
            margin: 0 0 20px;
        }
    }
    .faq-box .question,
    .faq-box .answer {
        padding-left: 0;
    }
    .table-hold {
        margin: 0 0 20px;
    }
}
.upload-bulk-contacts-modal {
    max-width: 700px;
    width: 100%;
    background-color: $body-bg;
    padding: 40px 16px;
    box-sizing: border-box;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: -700px;
    bottom: 0;
    z-index: 5;
    transition: right .5s ease-in-out;

    .close-button {
        top: 16px;
        right: 16px;
    }
    .title-modal + small {
        color: $text-color;
        margin-bottom: 40px;

        b {
            font-weight: 700;
        }
    }
    .upload-bulk-contacts-hold {
        max-width: 440px;
        margin: 0 auto;
    }
    .table-hold {
        margin: 0 0 24px;
    }
}
#upload-bulk-contacts-modal01 {
    display: none;

    &:checked ~ {

        .basic-overlay {
            opacity: 1;
            visibility: visible;
        }
        .upload-bulk-contacts-modal01 {
            right: 0;
        }
    }
}
#upload-bulk-contacts-modal02 {
    display: none;

    &:checked ~ {

        .basic-overlay {
            opacity: 1;
            visibility: visible;
        }
        .upload-bulk-contacts-modal02 {
            right: 0;
        }
    }
}
#close-modal {
    display: none;
}
.total-balance {
    margin: 0 0 6px;
    position: relative;

    &.open .dropdown-box {
        opacity: 1;
        visibility: visible;
    }
    .drop-btn {
        display: inline-flex;
        align-items: center;
        column-gap: 7px;
        cursor: pointer;

        &:after {
            content: '';
            width: 16px;
            min-width: 16px;
            height: 16px;
            background: url(../images/arrow-down-icon-circle.svg) center no-repeat;
            margin: 0 0 -2px;
        }
    }
    .dropdown-box {
        width: 240px;
        top: calc(100% + 10px);
        left: 0;
    }
}
.sub-accounts-box {
    display: flex;
    flex-wrap: wrap;
    column-gap: 75px;
    row-gap: 40px;
    text-align: center;

    .box {
        width: calc((100% - 150px)/3);
        background-color: $body-bg;
        border-radius: 4px;
        padding: 20px 40px;
        box-sizing: border-box;
    }
    .medium-title {
        font-size: $fs-semi-strong;
        text-align: center;
        margin: 0 0 10px;
    }
    .balance-value {
        display: block;
        font-weight: 500;
        background-color: $gray-color06;
        border-radius: 80px;
        padding: 10px;
        margin: 0 0 20px;

        b {
            font-weight: 700;
        }
    }
    .sub-account-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .quantity {
            font-weight: 500;
            color: $gray-color02;
        }
        .basic-button {
            margin: 0;
        }
    }
}
.sub-account-overview-header {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
    margin: 0 0 20px;

    .box {
        width: calc((100% - 60px)/3);
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $body-bg;
        border-radius: 8px;
        box-shadow: $main-shadow;
        padding: 36px;
        box-sizing: border-box;

        &:nth-child(3n + 2) .overview-icon {
            background-color: #FDFEE2;
        }
        &:nth-child(3n) .overview-icon {
            background-color: #FEE2E2;
        }
    }
    dl {
        font-size: $fs-small;

        dt {
            font-weight: 500;
            margin: 0 0 10px;
        }
        dd {
            font-size: $fs-bold;
            font-weight: 700;
            color: $text-color;
            position: relative;

            small {
                display: inline-block;
                font-weight: 500;
            }
        }
        .cash-balance {
            white-space: nowrap;
            border-radius: 8px;
            padding: 2px 8px;
            margin-top: 0;
            position: absolute;
            top: calc(100% + 5px);
            left: 0;
        }
    }
    .overview-icon {
        width: 40px;
        min-width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E2FCFE;
        border-radius: 50%;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
.sub-account-overview-body {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;

    .box {
        width: calc((100% - 30px)/2);
        background-color: $body-bg;
        padding: 20px 30px 0;
        box-sizing: border-box;
        position: relative;
    }
    .time-interval {
        top: 20px;
        right: 30px;
    }
    table {

        td {

            &:first-of-type {
                padding-left: 0;
            }
            &:last-of-type {
                font-size: $fs-medium;
                font-weight: 700;
                text-align: right;
                padding-right: 0;
            }
        }
    }
}