@import 'variables.scss';

.modal-window {
	max-width: 560px;
	width: 100%;
	background-color: $body-bg;
	border-radius: 8px;
	overflow: hidden;
	padding: 50px;
	box-sizing: border-box;
	position: absolute;
	top: -9999px;
	left: -9999px;

	.close-button {
		top: 16px;
		right: 16px;
	}
	.medium-title {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 24px;

		small {
			width: 100%;
			font-weight: normal;
			color: $placeholder-color;
		}
	}
	.form-modal-hold {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.bank-fields {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;

		.form-field {
			width: calc(50% - 5px);
		}
	}
	.resend-link {
		font-size: $fs-medium;
		margin: 0 auto 0 0;
	}
}
.title-modal {
	display: block;
	font-size: 22px;
	text-align: center;
	margin: 0 0 16px;

	+ small {
		max-width: 360px;
		color: $gray-color04;
		text-align: center;
		margin: 0 auto 20px;
	}
	mark {
		color: $link-color;
		background-color: transparent;
	}
}
.small-modal {
	max-width: 430px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;

	.delete-icon {
		width: 32px;
		margin: 0 auto 10px;

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	p {
		text-align: center;
	}
}
.modal-buttons {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	column-gap: 16px;
	row-gap: 16px;

	.cancel-btn {
		font-weight: 600;
		color: $link-color;
		background: none;
		padding: 0;
		margin: 0 auto 0 0;

		&:hover {
			text-decoration: underline;
		}
	}
	.basic-button {
		margin: 0;
	}
	.back-link {
		margin: 0 auto 0 0;
	}
}
.picture-hold {
	max-width: 335px;
	width: 100%;
	height: 270px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #EEF1F2;
	border-radius: 8px;
	padding: 20px;
	box-sizing: border-box;
	margin: 0 auto 15px;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}
.document-text {
	max-width: 335px;
	width: 100%;
	color: #432400;
	text-align: center;
	background: #F2F0E5;
	border-radius: 8px;
	padding: 7px 10px;
	box-sizing: border-box;
	margin: 0 auto;
}
.account-details-hold {
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 30px;
	background-color: $body-bg-blue;
	border-radius: 4px;
	padding: 30px 20px;
	box-sizing: border-box;
	margin: 0 0 30px;

	dl {
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 10px;
		margin: 0;
	}
	dt {
		color: $gray-color01;
	}
	dd {
		position: relative;
	}
	.copy-btn {
		position: absolute;
		top: 8px;
		left: calc(100% + 4px);
	}
}
.welcome-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	.check-img {
		width: 64px;
		margin: 0 0 20px;

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}
.invoice-window {
	max-width: 650px;
	color: $gray-color03;
	padding: 0;

	.close-button {
		top: 8px;
		right: 8px;
	}
}
.preview-title {
	text-align: center;
	border-bottom: 1px solid #EFF5FF;
	padding: 16px 32px;

	.strong-title {
		text-align: center;
		margin: 0 0 10px;
	}
	p {
		color: $gray-color04;
		margin: 0;
	}
}
.invoice-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	column-gap: 16px;
	border-bottom: 1px solid #EFF5FF;
	padding: 16px 32px;

	.col:last-child {
		text-align: right;
		padding-top: 16px;
	}
	.status-hold {
		display: flex;
		align-items: baseline;
		column-gap: 7px;
		font-weight: 500;
		margin: 0 0 24px;
	}
	.invoice-logo {
		max-width: 40px;

		img {
			display: block;
			max-width: 100%;
		}
	}
	.title-invoice {
		display: block;
		margin: 0 0 16px;
	}
	address {
		font-size: $fs-small;
		font-style: normal;
		margin: 0;
	}
}
.invoice-body {
	padding: 16px 32px;

	.invoice-lists {
		display: flex;
		flex-wrap: wrap;
		row-gap: 20px;
		margin: 0 0 30px;

		dl {
			width: 50%;

			&:nth-of-type(2n) {
				text-align: right;
			}
		}
		dt {
			margin: 0 0 8px;
		}
		dd {
			font-size: $fs-text;
		}
	}
	table {
		font-size: $fs-small;
		font-weight: 600;
		margin: 0 0 16px;

		th,
		td {
			height: 40px;
			padding: 0 10px;

			&:first-of-type {
				padding-left: 10px;
			}
			&:last-of-type {
				padding-right: 10px;
			}
		}
		th {
			font-size: $fs-small;
		}
	}
	.values-table {
		display: flex;
		flex-direction: column;
		row-gap: 5px;
		margin: 0 0 50px;

		dl {
			height: 25px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: $fs-small;
			font-weight: 500;
			background-color: $light-blue-bg04;
			padding: 0 15px;
		}
		dd {
			font-size: $fs-small;
			font-weight: 700;
		}
		.total-row {
			font-weight: 600;

			dd {
				font-weight: 900;
				color: $text-color;
			}
		}
	}
	.note-pay-hold {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.col:last-child {
			text-align: right;
		}
		.note-pay-title {
			display: block;
			margin: 0 0 8px;
		}
		ul {
			display: flex;
			flex-direction: column;
			row-gap: 8px;
			font-weight: 500;
		}
	}
}
.invoice-footer {
	min-height: 64px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 16px;
	row-gap: 16px;
	background-color: $light-blue-bg01;
	border: 1px solid $border-color03;
	padding: 20px 32px;
	box-sizing: border-box;

	.date {
		font-weight: 500;

		b {
			font-weight: 700;
		}
	}
}
.yellow-warning {
	max-width: 320px;
	width: 100%;
	display: block;
	font-size: $fs-minimal;
	color: #AB7E09;
	background-color: #FFF9EB;
	border-radius: 4px;
	position: relative;
	padding: 7px 15px 7px 32px;
	box-sizing: border-box;
	margin: 0 0 24px;

	&:before {
		content: '';
		width: 12px;
		height: 12px;
		background: url(../images/warning-icon.svg) center no-repeat;
		background-size: contain;
		position: absolute;
		top: 8px;
		left: 10px;
	}
}
.create-an-invoice-modal {
	max-width: 100%;
	height: 100%;
	background-color: $body-bg-blue;
	border-radius: 0;
	padding: 0;

	.close-button {
		top: 12px;
		right: auto;
		left: 42px;
	}
	.title-modal {
		text-align: left;
		background-color: $body-bg;
		box-shadow: $main-shadow;
		padding: 11px 11px 11px 94px;
		margin: 0;
		position: relative;
	}
	.invoice-header {
		align-items: center;

		.col:last-child {
			padding: 0;
		}
	}
}
.create-an-invoice-box {
	height: calc(100% - 55px);
	display: flex;
	column-gap: 24px;
	padding-left: 94px;

	.create-col {
		width: calc(54% - 12px);
		background-color: $body-bg;
		position: relative;
		padding: 0 0 62px;
		box-sizing: border-box;
		margin: 30px 0 0;

		form {
			height: 100%;
		}
	}
	.invoice-col {
		width: calc(46% - 12px);
		overflow-y: auto;
		background-color: $body-bg;
	}
}
.create-col-hold {
	height: 100%;
	overflow-y: auto;
}
.create-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	column-gap: 16px;
	border-bottom: 1px solid #C1D2EE;
	padding: 16px 32px;

	.logo-hold {
		text-align: center;

		figure {
			width: 102px;
			height: 102px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $form-field-bg;
			box-shadow: $main-shadow;
			border-radius: 50%;
			margin: 0 0 6px;

			img {
				max-width: 75%;
				max-height: 75%;
			}
		}
		a {
			display: block;
			font-size: $fs-minimal;
			font-weight: 700;
		}
	}
	.title-hold {
		max-width: 310px;
		width: 100%;

		.form-field {
			margin: 0 0 10px;
		}
		address {
			font-weight: 600;
			color: $gray-color03;
			font-style: normal;
			text-align: right;
		}
	}
}
.create-contacts {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	column-gap: 16px;
	row-gap: 16px;
	border-bottom: 1px solid #C1D2EE;
	padding: 16px 32px;

	.form-field {
		max-width: 39%;
		width: 100%;
		margin: 0;
	}
	.invoice-title {
		display: none;
	}
}
.create-tables {
	padding: 16px 32px;

	table {
		font-weight: 600;

		th,
		td {
			padding: 5px 20px;
		}
		th {
			height: 40px;
			font-size: $fs-text;
			font-weight: 600;
			border-bottom: 1px solid $border-color04;

			&:first-of-type {
				min-width: 210px;
				padding-left: 20px;
			}
			&:last-of-type {
				padding-right: 0;
			}
		}
		td {
			border-bottom: none;

			&:first-of-type {
				min-width: 210px;
				padding-left: 0;
			}
			&:last-of-type {
				padding-right: 0;
			}
		}
		tr {

			&:nth-of-type(2) td {
				padding-top: 24px;
			}
			&:hover {
				background-color: transparent;
			}
		}
	}
	.delete-item {
		display: block;
		width: 12px;
		height: 16px;
		background: url(../images/delete-icon-red-solid.svg) center no-repeat;
		background-size: contain;
		padding: 0;
	}
	.add-btn {
		margin: 5px 0 24px;
	}
	.total-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: $fs-semi-strong;
		font-weight: 500;
		color: $gray-color02;
		column-gap: 16px;
		background-color: $light-blue-bg04;
		padding: 15px 20px;
		margin: 0 0 14px;

		dd {
			font-size: $fs-semi-strong;
			font-weight: 700;
			color: $gray-color02;
		}
		&.subtotal {
			font-size: $fs-text;
			padding: 8px 20px;

			dd {
				font-size: $fs-medium;
			}
		}
	}
	.add-link-hold {
		padding: 0 0 0 16px;
		margin: 0 0 14px;
	}
	.add-link {
		font-size: $fs-small;
	}
	.note-hold {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		column-gap: 16px;
		row-gap: 24px;

		.form-field {
			max-width: 39%;
			width: 100%;
			margin: 0;
		}
	}
}
.create-buttons {
	height: 62px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 40px;
	background-color: $body-bg;
	border: 1px solid $border-color03;
	box-shadow: $main-shadow;
	padding: 0 32px;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;

	.basic-button {
		margin: 0;
	}
}
.contacts-details-hold {
	display: flex;
	flex-direction: column;
	row-gap: 40px;
	column-gap: 16px;

	dl {
		display: flex;
		align-items: center;
		justify-content: space-between;

		a {
			font-size: $fs-text;
			font-weight: 700;
		}
	}
	.transactions-link {
		margin: 30px auto 0;
	}
}