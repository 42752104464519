@import 'variables.scss';

@media only screen and (max-width: 1439px) {
	.content {
		padding: 0 24px 24px;
	}
	.home-start {
		padding-left: 0;
	}
}

@media only screen and (max-width: 1328px) {
	.sign-up-main .business-type-addition {
		right: calc(100% + 10px);
	}
	.integrations-box .box {
		width: calc((100% - 56px)/3);
	}
	.team-tabs {

		.tabs-menu-col {
			width: 150px;
			padding: 24px;
		}
		.tabs-content-col {
			width: calc(100% - 150px);
			padding: 24px;
		}
	}
	.roles-box {
		column-gap: 24px;

		.box {
			max-width: calc(50% - 12px);
		}
	}
	.home-box .account-header {
		flex-direction: column;
		align-items: flex-start;
	}
	.sub-accounts-box {
		column-gap: 16px;
		row-gap: 16px;

		.box {
			width: calc((100% - 32px)/3);
			padding: 16px;
		}
	}
}

@media only screen and (max-width: 1023px) {
	h1,
	h2,
	.details-box .details-value {
		font-size: $fs-bold;
		line-height: (31px/24px);
	}
	.send-field input[type="text"] {
		font-size: $fs-text;
	}
	.basic-button.actions-button,
	.title-top .basic-button {
		height: 32px;
		padding: 0 16px;
	}
	.footnote-hold {
		display: none;
	}
	.modal-window {
		padding: 24px 16px;
	}
	.invoice-window {
		padding: 0;
	}
	.sign-up-main {

		h1 + p {
			font-size: $fs-small;
		}
		.business-type-addition {
			display: none !important;
		}
		.help-alert {
			position: static;
			margin: auto auto 0;
		}
		.back-link {
			top: 94px;
			left: 24px;
		}
	}
	.sign-up-header {
		padding-top: 24px;
	}
	.sign-up-footer {
		display: none;
	}
	.review-hold .title-review {
		column-gap: 16px;
		font-size: $fs-semi-strong;
		padding: 20px;

		&:before {
			width: 16px;
			min-width: 16px;
			height: 16px;
			background-size: 10px 7px;
			margin: 0 0 -2px;
		}
	}
	.review-drop-hold {
		padding: 0 24px 24px 52px;

		dd {
			font-size: $fs-text;
		}
	}
	.content {
		width: 100%;
	}
	.payment-content {
		padding-bottom: 72px;
	}
	.sidebar {
		height: auto;
		position: fixed;
		bottom: 0;
		left: -200px;
	}
	.user-hold {
		height: 162px;
		border-bottom: none;
		padding: 80px 30px 40px;
	}
	.main-menu {
		height: calc(100% - 162px);
		overflow-y: auto;
		padding-top: 0;

		li.active a,
		li.active .drop-btn {
			border-right-width: 4px;
		}
	}
	.main-header {
		height: 64px;
		background-color: $body-bg;
		padding: 0 24px;
		margin: 0 -24px 10px;

		.search-hold {
			display: none;
		}
		&:after {
			display: none;
		}
	}
	.burger-btn {
		display: block;
	}
	.basic-button.actions-button {
		order: 2;
	}
	.notification-btn {
		order: 3;
		width: 32px;
		min-width: 32px;
		height: 32px;
		display: flex;
		margin: 0;
	}
	.home-box {
		flex-direction: column;
		row-gap: 8px;

		.box {
			padding: 16px 16px 0;
		}
		.cash-balance-box {
			width: 100%;
			min-height: auto;

			.cash-title,
			.balance-value {
				text-align: center;
			}
		}
		.recent-transactions-box {
			width: 100%;
			min-height: auto;
		}
		.cash-inflow-box,
		.cash-outflow-box {
			width: 100%;
			min-height: auto;

			table td:last-child {
				font-size: $fs-text;
			}
		}
		.progress-text {
			justify-content: center;
		}
		.more-link {
			padding: 12px 0;
		}
		table {
			font-size: $fs-small;
		}
		.inflow-outflow-list {
			font-size: $fs-minimal;
		}
		.chart-img.absent {
			margin: 50px 0 20px;
		}
		&.account-wrap .cash-balance-box {
			width: 100%;
		}
		.spent-box {
			width: 100%;
		}
		.account-box {
			width: 100%;
		}
		.account-details-hold {
			padding: 30px 20px;
		}
		.title-with-interval {
			justify-content: center;
		}
		.footer-account {
			column-gap: 16px;
		}
	}
	.absent-hold {
		margin: 50px 0;
	}
	.table-hold .absent-hold {
		min-height: auto;
	}
	.home-start {
		padding-top: 0;
	}
	.status-box {
		padding: 50px 24px;

		.status {
			top: 16px;
			right: 16px;
		}
	}
	.faq-box {

		.question {
			padding: 16px 24px;
		}
		.answer {
			padding: 0 24px;
		}
	}
	.help-box {
		width: 140px;

		.open-close-btn {
			padding: 10px 20px;
		}
		&.open > ul {
			padding-top: 10px;
		}
	}
	.details-box {
		padding: 24px 24px 0;

		hr {
			margin: 0 0 16px;
		}
		dl {
			margin: 0 0 16px;

			dd {
				font-size: $fs-text;
			}
		}
		.details-footer {
			margin: auto -24px 0;
		}
	}
	.payment-content {

		.progress-indicator {
			margin-bottom: 20px;
		}
		h1 {
			margin: 0 0 16px;
		}
	}
	.title-payment {
		margin: 0 0 20px;
	}
	.create-an-invoice-modal {
		padding: 0;

		.close-button {
			left: 24px;
		}
		.title-modal {
			text-align: center;
			padding-left: 11px;
			margin: 0 0 10px;
		}
	}
	.create-an-invoice-box {
		overflow-y: auto;
		display: block;
		padding: 0;

		.create-col {
			width: 100%;
			padding: 0;
			margin: 0 0 10px;

			form {
				height: auto;
			}
		}
		.invoice-col {
			width: 100%;
			overflow: hidden;
		}
	}
	.create-col-hold {
		height: auto;
		overflow: hidden;
	}
	.create-header {
		padding: 24px;

		.logo-hold figure {
			width: 64px;
			height: 64px;
		}
		.title-hold address {
			font-size: $fs-small;
		}
	}
	.create-contacts,
	.create-tables,
	.preview-title,
	.invoice-header,
	.invoice-body {
		padding: 24px;
	}
	.create-buttons {
		position: static;
		padding: 0 24px;
	}
	.contacts-details-hold {
		row-gap: 24px;

		.transactions-link {
			margin: 0 auto;
		}
	}
	.settings-box {
		max-width: 100%;
		padding: 20px;
	}
	.business-details-box {
		flex-direction: column;

		.important-text {
			max-width: 100%;
			padding: 16px;
		}
	}
	.security-box {
		flex-direction: column;

		.settings-box {
			padding: 20px;

			&:first-child,
			&:last-child {
				width: 100%;
			}
		}
		.table-hold {
			margin: 0 -20px;
		}
	}
	.toast-notification {
		top: 24px;
		right: 24px;
	}
	.bulk-payment-header {
		padding: 24px;

		dl dd {
			font-size: $fs-strong;
		}
	}
	.faq-modal .faq-menu {
		display: none;
	}
	.sub-account-overview-header {
		row-gap: 10px;

		.box {
			width: 100%;
			padding: 16px;
		}
		dl .cash-balance {
			position: static;
			margin: 10px 0 0 6px;
		}
	}
	.sub-account-overview-body {

		.box {
			padding: 16px 16px 0;
		}
		.time-interval {
			top: 16px;
			right: 16px;
		}
	}
}

@media only screen and (max-width: 719px) {
	h1, h2 {
		text-align: left;
	}
	.radio-buttons {
		flex-direction: column;

		li {
			max-width: 100%;
		}
	}
	.code-inputs {
		margin: 0 0 10px;

		input[type="number"] {
			width: 48px;
			height: 48px;
		}
	}
	.form-field.half {
		width: 100%;
	}
	.modal-window {

		.resend-link {
			font-size: $fs-text;
			margin-bottom: 22px;

			+ .modal-buttons .basic-button {
				width: 100%;
			}
		}
		.bank-fields .form-field {
			width: 100%;
		}
	}
	.sign-up-main {

		p {
			text-align: left;

			br {
				display: none;
			}
		}
		.bottom-action {
			margin-top: auto;
		}
		.radio-buttons li {
			width: 100%;
		}
		.help-alert {
			max-width: 100%;
		}
		.forgot-password-link {
			margin: -16px 0 24px;
		}
		.back-link {
			top: -44px;
		}
	}
	.form-hold {
		max-width: 100%;
	}
	.sign-up-letter {

		h1,
		p {
			text-align: center;
		}
	}
	.sign-up-header.with-user {
		padding-bottom: 24px;

		.basic-logo {
			display: none;
		}
		.user-name {
			column-gap: 10px;
			font-size: $fs-small;
		}
	}
	.progress-indicator {
		max-width: 340px;
		padding: 0;

		.item-text {
			display: none;
			top: auto;
			bottom: calc(100% + 24px);
		}
		li:after {
			width: 110px;
		}
		.mobile-item {
			display: block;
		}
		&.six-items {
			max-width: 340px;
			overflow: hidden;

			li::after {
				width: 65px;
			}
		}
		&.three-items {
			max-width: 340px;
			overflow: hidden;

			li::after {
				width: 170px;
			}
		}
	}
	.review-drop-hold dl {
		width: calc((100% - 16px)/2);
	}
	.review-form .basic-button {
		max-width: 100%;
	}
	.filter-drop {
		width: 150px;
	}
	.pagination {
		display: none;
	}
	.payment-content h1 {
		text-align: center;
	}
	.invoice-header {
		flex-direction: column;
		padding: 16px;

		.status-hold {
			margin: 0 0 16px;
		}
		.col:last-child {
			text-align: left;
		}
		address br {
			display: none;
		}
	}
	.invoice-body {
		padding: 16px;
	}
	.invoice-footer {
		flex-direction: column;
		padding: 16px;
	}
	.create-header {
		align-items: center;

		.invoice-title {
			display: none;
		}
	}
	.create-contacts {
		flex-direction: column;
		row-gap: 24px;

		.invoice-title {
			display: block;
		}
		.form-field {
			max-width: 100%;
		}
	}
	.create-tables {

		.table-hold {
			margin: 0 -24px;
		}
		table {

			th,
			td {
				padding: 5px 15px;

				&:first-of-type {
					padding-left: 24px;
				}
				&:last-of-type {
					padding-right: 24px;
				}
			}
			tr:nth-of-type(2) td {
				padding-top: 14px;
			}
		}
		.note-hold {
			flex-direction: column;

			.form-field {
				max-width: 100%;
			}
		}
	}
	.create-an-invoice-modal .invoice-header {
		align-items: flex-start;

		.col:last-child {
			padding-top: 16px;
		}
	}
	.title-top .title-buttons {
		width: 100%;
		padding-top: 10px;
	}
	.integrations-box {
		row-gap: 8px;

		.box {
			width: 100%;
		}
	}
	.team-tabs {
		flex-direction: column;

		.tabs-menu-col {
			width: 100%;
			border-right: none;
			border-bottom: 1px solid $border-color02;
		}
		.team-tabs-menu {
			flex-direction: row;
			column-gap: 24px;
		}
		.tabs-content-col {
			width: 100%;
		}
	}
	.roles-box {
		flex-direction: column;
		row-gap: 24px;

		.box {
			max-width: 100%;
		}
	}
	.bulk-payment-header {
		flex-direction: column;
		row-gap: 14px;

		dl {
			width: 100%;
		}
	}
	.details-box .details-header {
		flex-direction: column;
	}
	.home-box .spent-box .cell {
		width: 100%;
		border-right: none;

		&:nth-last-child(2) {
			border-bottom: 1px solid #D1F6FF;
		}
	}
	.sub-accounts-box .box {
		width: 100%;
	}
	.sub-account-overview-body {
		row-gap: 10px;

		.box {
			width: 100%;
		}
	}
}