@import 'variables.scss';

#check-sidebar {
	display: none;

	&:checked ~ {

		.sidebar {
			left: 0;
		}
		.basic-overlay {
			opacity: 1;
			visibility: visible;
		}
	}
}
.sidebar {
	width: 200px;
	min-width: 200px;
	height: 100vh;
	background-color: $body-bg;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 4;
	transition: left .5s ease-in-out;
}
.user-hold {
	height: 72px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: 1px solid $border-color01;
	padding: 0 30px;
	box-sizing: border-box;
	white-space: nowrap;

	strong {
		font-size: $fs-medium;
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	small {
		color: #767575;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.main-menu {
	height: calc(100% - 72px);
	display: flex;
	flex-direction: column;
	row-gap: 12px;
	font-size: 15px;
	padding: 20px 0;
	box-sizing: border-box;

	li {

		&.active {

			> a,
			> .drop-btn{
				font-weight: 700;
				color: $link-color;
				border-right: 2px solid $link-color;

				&:before {
					filter: none;
				}
			}
		}
		&.without-icon {

			a {
				padding-left: 30px;

				&:before {
					display: none;
				}
			}
			+ .logout-item {
				border-top: 1px solid $gray-color06;
				padding-top: 12px;
				margin-top: auto;
			}
		}
	}
	a,
	.drop-btn {
		height: 32px;
		display: flex;
		align-items: center;
		column-gap: 6px;
		font-weight: 500;
		color: $gray-color-main;
		padding: 0 20px;
		cursor: pointer;
		transition: color .1s ease-in-out;

		&:hover {
			color: $link-color;
			text-decoration: none;

			&:before {
				filter: none;
			}
		}
		&:before {
			content: '';
			width: 18px;
			min-width: 18px;
			height: 18px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			filter: grayscale(1);
			margin: 0 0 -4px;
			transition: filter .1s ease-in-out;
		}
	}
	.drop-btn:after {
		content: '';
		width: 6px;
		min-width: 6px;
		height: 8px;
		background: url(../images/arrow-right-icon-gray.svg) center no-repeat;
		box-sizing: border-box;
		margin: 0 0 -4px auto;
		transition: transform .1s ease-in-out;
	}
	.drop-menu {
		display: none;
		flex-direction: column;
		row-gap: 15px;
		font-size: $fs-small;
		padding-top: 10px;

		a {
			height: 24px;
		}
	}
	.open-box.open {

		.drop-btn:after {
			transform: rotate(-90deg);
		}
		.drop-menu {
			display: flex;
		}
	}
	.home-item a:before {
		background-image: url(../images/menu-home-icon.svg);
	}
	.payments-item a:before {
		background-image: url(../images/menu-payments-icon.svg);
	}
	.transactions-item a:before {
		background-image: url(../images/menu-transactions-icon.svg);
	}
	.invoices-item a:before {
		background-image: url(../images/menu-invoices-icon.svg);
	}
	.credit-item a {
		opacity: .5;
		pointer-events: none;

		&:before {
			background-image: url(../images/menu-credit-icon.svg);
			filter: none;
		}
	}
	.contacts-item a:before {
		background-image: url(../images/menu-contacts-icon.svg);
	}
	.accounts-item .drop-btn:before {
		background-image: url(../images/menu-accounts-icon.svg);
	}
	.settings-item {
		border-top: 1px solid $gray-color06;
		padding-top: 12px;
		margin-top: auto;

		a:before {
			background-image: url(../images/menu-settings-icon.svg);
		}
	}
	.logout-item a {
		color: $red-color02;

		&:before {
			background-image: url(../images/menu-logout-icon.svg);
			filter: none;
		}
	}
}
.basic-overlay {
	height: auto;
	background-color: $overlay-bg;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 3;
	opacity: 0;
	visibility: hidden;
	cursor: pointer;
	padding: 0;
	transition: all .5s ease-in-out;
}