@import 'variables.scss';

/*	   dropdown   	*/
.dropdown-box {
    background-color: $body-bg;
    box-shadow: $shadow01;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease-in-out;

    ul {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        font-size: $fs-small;

        a {
            font-weight: 500;
            color: $text-color;
        }
    }
    .item-with-icon {
        display: flex;
        align-items: center;
        column-gap: 7px;

        &:before {
            content: '';
            width: 12px;
            min-width: 12px;
            height: 12px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin: 0 0 -2px;
        }
    }
    .view-details:before {
        background-image: url(../images/view-details-icon.svg);
    }
    .edit-details:before {
        background-image: url(../images/edit-details-icon.svg);
    }
    .send-money:before {
        background-image: url(../images/send-money-icon.svg);
    }
    .delete:before {
        background-image: url(../images/delete-icon-black.svg);
    }
    .make-bulk-payment:before {
        background-image: url(../images/make-bulk-payment-icon.svg);
    }
    .send-money-circuit:before {
        width: 16px;
        min-width: 16px;
        height: 16px;
        background-image: url(../images/send-icon.svg);
    }
    .request-money:before {
        width: 16px;
        min-width: 16px;
        height: 16px;
        background-image: url(../images/request-icon.svg);
    }
    .share-account-details:before {
        width: 16px;
        min-width: 16px;
        height: 16px;
        background-image: url(../images/share-icon-black.svg);
    }
    .create-invoice:before {
        width: 16px;
        min-width: 16px;
        height: 16px;
        background-image: url(../images/create-icon.svg);
    }
}
/*	   end  dropdown   	*/

/*	   form  fields   	*/
.form-field {
    width: 100%;
    position: relative;
    margin: 0 0 24px;

    &.half {
        width: calc(50% - 12px);
    }
    .title-form-field {
        display: flex;
        align-items: center;
        font-size: $fs-medium;
        margin: 0 0 8px;
        position: relative;
    }
    .error-text {
        display: none;
        color: $red-color01;
        margin-top: 4px;
    }
    .add-contact-link,
    .download-file-link,
    .download-sample-file,
    .select-contact-list {
        display: inline-block;
        vertical-align: top;
        font-size: $fs-small;
        margin-top: 4px;
    }
    .download-file-link {
        margin-top: 10px;
    }
    .checkbox-list {
        font-size: $fs-text;
        padding-top: 6px;

        label {

            &:before {
                width: 13px;
                height: 13px;
                top: 5px;
            }
            &:after {
                width: 7px;
                height: 5px;
            }
        }
    }
    small {
        color: $gray-color05;
        padding-top: 10px;
    }
}
.cash-balance {
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    color: #118DAD;
    background-color: #E9FAFF;
    border-radius: 4px;
    padding: 8px;
    margin-top: 10px;

    b {
        font-weight: 700;
    }
}
.error-field {

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    textarea,
    .file-label {
        border-color: $red-color01;
    }
    .error-text {
        display: block;
    }
}
.footnote-hold {
    position: relative;
    
    &:hover .footnote-text {
        opacity: 1;
        visibility: visible;
    }
}
.footnote-icon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(../images/footnote-icon.svg) center no-repeat;
    cursor: pointer;
    margin: 0 0 -6px;
}
.footnote-text {
    display: block;
    font-size: $fs-text;
    font-weight: normal;
    color: #DEF8FF;
    white-space: nowrap;
    background-color: $text-color;
    border-radius: 4px;
    padding: 12px 16px;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease-in-out;
    z-index: 1;
}
.view-link {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: $fs-small;
    margin: 0 0 0 auto;

    &:before {
        content: '';
        width: 12px;
        min-width: 12px;
        height: 12px;
        background: url(../images/eye-icon.svg) center no-repeat;
        background-size: contain;
        margin: 0 0 -4px;
    }
}
/*	   end  form  fields   	*/

/*	   inputs,  textarea,  select   	*/
textarea {
    width: 100% !important;
    max-width: 100% !important;
    min-height: 100px;
}
input:-moz-placeholder {
    font-weight: normal;
    color: $placeholder-color;
    opacity: 1;
}
input::-moz-placeholder {
    font-weight: normal;
    color: $placeholder-color;
    opacity: 1;
}
input::-webkit-input-placeholder {
    font-weight: normal;
    color: $placeholder-color;
}
input:focus:-moz-placeholder {
    font-weight: normal;
    color: $placeholder-color;
    opacity: 1;
}
input:focus::-moz-placeholder {
    font-weight: normal;
    color: $placeholder-color;
    opacity: 1;
}
input:focus::-webkit-input-placeholder {
    font-weight: normal;
    color: $placeholder-color;
}
textarea:-moz-placeholder {
    font-weight: normal;
    color: $placeholder-color;
    opacity: 1;
}
textarea::-moz-placeholder {
    font-weight: normal;
    color: $placeholder-color;
    opacity: 1;
}
textarea::-webkit-input-placeholder {
    font-weight: normal;
    color: $placeholder-color;
}
textarea:focus:-moz-placeholder {
    font-weight: normal;
    color: $placeholder-color;
    opacity: 1;
}
textarea:focus::-moz-placeholder {
    font-weight: normal;
    color: $placeholder-color;
    opacity: 1;
}
textarea:focus::-webkit-input-placeholder {
    font-weight: normal;
    color: $placeholder-color;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"],
textarea,
select,
.select-btn {
    width: 100%;
    height: 48px;
    font-size: $fs-text;
    line-height: $lh-text;
    font-family: $text-font;
    color: $text-color;
    font-weight: 600;
    background: $form-field-bg;
    border: 1px solid $form-field-bg;
    border-radius: 4px;
    outline: 0 none;
    padding: 0 20px;
    box-sizing: border-box;
    margin: 0;
    transition: all .1s ease-in-out;

    &:focus {
        border-color: $link-color;
    }
    &.disabled,
    &[disabled] {
        pointer-events: none;
        color: rgba($text-color, .3);
    }
}
textarea {
    width: 100% !important;
    max-width: 100% !important;
    min-height: 126px;
    padding: 12px 20px;
}
.phone-field {
    position: relative;

    input[type="tel"] {
        padding-left: 96px;
    }
    .code-phone {
        width: 76px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $placeholder-color;
        border-right: 1px solid #D4D8E6;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
    }
}
.password-field {
    position: relative;

    input[type="password"],
    input[type="text"] {
        padding-right: 60px;
    }
    .show-btn {
        font-size: 12px;
        font-weight: 500;
        color: $link-color;
        background: none;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        padding: 0;
    }
}
.send-field {
    position: relative;

    input[type="text"] {
        font-size: $fs-bold;
        padding-left: 90px;
    }
    .country-hold {
        width: 62px;
        height: 24px;
        display: flex;
        align-items: center;
        column-gap: 6px;
        font-size: $fs-small;
        font-weight: 500;
        color: $gray-color05;
        background-color: #EFF6FD;
        border-radius: 4px;
        overflow: hidden;
        padding: 2px 4px;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 14px;
        transform: translateY(-50%);

        img {
            max-width: 20px;
            max-height: 100%;
        }
    }
}
.select-field {
    position: relative;
    
    &:after {
        content: '';
        background: url(../images/arrow-down-icon-gray.svg) center no-repeat;
        background-size: contain;
        width: 12px;
        height: 6px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }
    &.open .select-drop {
        opacity: 1;
        visibility: visible;
    }
}
.select-open-box.open .select-drop {
    opacity: 1;
    visibility: visible;
}
select {
    color: $placeholder-color;
    font-weight: normal;
    text-overflow: ellipsis;
    padding-right: 40px;
}
option {
    font-weight: normal;
    padding: 0 20px;
}
.select-btn {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $placeholder-color;
    cursor: pointer;
    padding-top: 12px;
    padding-right: 40px;
    padding-bottom: 13px;

    b {
        font-weight: 500;
        color: $text-color;
    }
}
.select-drop {
    width: 160px;
    top: calc(100% + 10px);
    left: 0;

    .search-hold {
        margin: 0 0 8px;

        input[type="text"] {
            height: 32px;
            font-size: $fs-minimal;
            padding: 0 22px;

            &:focus {
                padding-left: 7px;
            }
        }
        .search-icon,
        .reset-icon {
            width: 8px;
            height: 8px;
            left: 7px;
        }
        .reset-icon {
            right: 7px;
            left: auto;
        }
    }
    > a {
        display: block;
        font-size: $fs-small;
        margin-top: 16px;
    }
}
input[type="file"] {
    display: none;
}
.file-label {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 10px;
    font-size: $fs-small;
    color: $placeholder-color;
    background: $light-gray-color url(../images/cloud-icon.svg) 30px center no-repeat;
    border: 1px dashed #BABABA;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    padding: 12px 12px 12px 82px;

    .file-name {
        font-size: $fs-text;
    }
    .progress-row {
        width: 100%;
        height: 4px;
        background-color: #DBDBDB;
        border-radius: 16px;
        position: relative;
    }
    .colored-row {
        background-color: $link-color;
        border-radius: 16px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
    }
    .progress-percent {
        color: #000;
    }
    &.loaded-file {
        padding-right: 56px;

        .file-name {
            color: #1B1B25;
        }
    }
    .close-button {
        top: 50%;
        right: 32px;
        transform: translateY(-50%);
    }
    .size-file {
        color: #585A60;
        background-color: #EEECEC;
        border-radius: 4px;
        padding: 2px 4px;
    }
}
.close-button {
    width: 32px;
    height: 32px;
    background: #F2F3F0 url(../images/close-icon.svg) center no-repeat;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
}
.search-hold {
    max-width: 640px;
    width: 100%;
    position: relative;

    input[type="text"] {
        height: 40px;
        background: $body-bg;
        border: none;
        box-shadow: $main-shadow;
        padding: 0 50px;

        &:focus {
            border-color: $link-color;
            padding-left: 18px;

            ~ {
                .search-icon {
                    display: none;
                }
                .reset-icon {
                    display: block;
                }
                .search-result {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .search-icon {
        width: 17px;
        height: 17px;
        background: url(../images/search-icon.svg) center no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 18px;
        transform: translateY(-50%);
    }
    .reset-icon {
        display: none;
        width: 16px;
        height: 16px;
        background: url(../images/close-icon.svg) center no-repeat;
        background-size: contain;
        border: none;
        position: absolute;
        top: 50%;
        right: 22px;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.search-result {
    background-color: $white-color;
    box-shadow: $main-shadow;
    border-radius: 0 0 4px 4px;
    padding: 10px 18px 18px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease-in-out;

    .box:not(:last-child) {
        border-bottom: 1px solid $border-color02;
        margin: 0 0 8px;
    }
    .title-search {
        display: block;
        color: $gray-color02;
        margin: 0 0 8px;
    }
    .search-row {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-size: $fs-small;
        margin: 0 0 12px;
    }
    .cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:nth-child(1) {
            width: 25%;
        }
        &:nth-child(2) {
            width: 25%;
        }
        &:nth-child(3) {
            width: 35%;
        }
        &:nth-child(4) {
            width: 15%;
            text-align: right;
        }
    }
}
.code-inputs {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin: 0 0 20px;

    input[type="number"] {
        width: 64px;
        height: 64px;
        font-size: 27px;
        font-weight: 700;
        text-align: center;
        padding: 0;
        -moz-appearance: textfield;
        
        &:focus,
        &:hover,
        &.typed {
            background-color: $light-blue-bg03;
            border-color: $link-color;
        }
        &.typed {
            border: none;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}
.chips-hold {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    background-color: $light-gray-color;
    border-radius: 4px;
    padding: 10px;

    .chip-item {
        height: 32px;
        max-width: 100%;
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-weight: 500;
        color: $link-color;
        background-color: $light-blue-bg03;
        border-radius: 4px;
        overflow: hidden;
        padding: 0 10px;

        .close-btn {
            width: 12px;
            min-width: 12px;
            height: 12px;
            background: url(../images/close-icon-blue.svg) center no-repeat;
            background-size: 60%;
            border: 1px solid $link-color;
            border-radius: 50%;
            padding: 0;
            box-sizing: border-box;
            margin: 0 0 -2px;
        }
        &:first-child {
            color: $text-color;
            background-color: $gray-bg;
        }
    }
}
.recipient-field {

    > input[type="text"]:focus ~ .dropdown-box {
        opacity: 1;
        visibility: visible;
    }
    > .dropdown-box {
        width: 250px;
        top: 90px;
        left: 0;

        small {
            padding-top: 0;
            margin: 0 0 12px;
        }
        mark {
            font-weight: 700;
            color: $link-color;
            background-color: transparent;
        }
    }
}
/*	   end  inputs,  textarea,  select   	*/

/*	   buttons   	*/
.basic-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 40px;
    font-size: $fs-text;
    line-height: $lh-text;
    font-family: $text-font;
    font-weight: 600;
    color: $white-color;
    text-align: center;
    background-color: $link-color;
    border: 0 none;
    outline: 0 none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 0 20px;
    position: relative;
    padding: 0 20px;
    box-sizing: border-box;
    transition: all .1s ease-in-out;

    &:hover {
        opacity: .8;
        text-decoration: none;
    }
    &.disabled,
    &:disabled {
        opacity: .3;
        pointer-events: none;
    }
    &.light-blue-button {
        color: $link-color;
        background-color: #F2FCFF;
    }
    &.red-button {
        background-color: #A10E0E;
    }
    &.border-button {
        color: $link-color;
        background-color: transparent;
        border: 1px solid $link-color;
    }
    &.drop-button,
    &.actions-button {
        column-gap: 8px;

        &:after {
            content: '';
            width: 12px;
            min-width: 12px;
            height: 6px;
            background: url(../images/arrow-down-icon-white.svg) center no-repeat;
            background-size: contain;
            margin: 0 0 -4px;
        }
    }
    &.actions-button {
        border-radius: 80px;
    }
    &.filter-button {
        column-gap: 10px;
        color: #7A8082;
        background-color: #F4F6F6;

        &:before {
            content: '';
            width: 16px;
            min-width: 16px;
            height: 16px;
            background: url(../images/filter-icon.svg) center no-repeat;
            background-size: contain;
            margin: 0 0 -2px;
        }
    }
    &.small-button {
        height: 24px;
        font-size: $fs-small;
    }
    &.export-button {
        column-gap: 10px;
        color: $link-color;
        background-color: transparent;
        border: 1px solid $link-color;

        &:before {
            content: '';
            width: 16px;
            min-width: 16px;
            height: 16px;
            background: url(../images/export-icon.svg) center no-repeat;
            background-size: contain;
            margin: 0 0 -2px;
        }
    }
    &.download-button {
        column-gap: 4px;

        &:before {
            content: '';
            width: 20px;
            min-width: 20px;
            height: 20px;
            background: url(../images/export-icon-white.svg) center no-repeat;
            background-size: contain;
            margin: 0 0 -2px;
        }
    }
    &.selected-filter {
        column-gap: 16px;
        color: $link-color;
        background-color: transparent;
        border: 1px solid $link-color;
        border-radius: 20px;
        margin: 0;

        .close-btn {
            width: 12px;
            min-width: 12px;
            height: 12px;
            background: url(../images/close-icon-blue.svg) center no-repeat;
            background-size: contain;
            cursor: pointer;
            margin: 0 0 -2px;
        }
    }
    &.add-button {
        column-gap: 10px;

        &:before {
            content: '';
            width: 16px;
            min-width: 16px;
            height: 16px;
            background: url(../images/add-icon-white.svg) center no-repeat;
            background-size: contain;
        }
    }
}
button[type="button"] {
    font-family: $text-font;
    border: none;
    outline: none;
    cursor: pointer;
}
.copy-btn {
    width: 10px;
    min-width: 10px;
    height: 10px;
    background: url(../images/copy-icon.svg) center no-repeat;
    background-size: contain;
}
.download-link {
    display: inline-flex;
    align-items: center;
    column-gap: 8px;

    &:before {
        content: '';
        width: 16px;
        min-width: 16px;
        height: 16px;
        background: url(../images/download-icon.svg) center no-repeat;
        background-size: contain;
        margin: 0 0 -2px;
    }
}
.more-link {
    display: block;
    font-size: $fs-minimal;
    text-transform: uppercase;
    text-align: center;
}
.resend-btn,
.mark-paid-btn,
.make-void-btn,
.download-btn,
.edit-btn,
.delete-btn {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-weight: 500;

    &:before {
        content: '';
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 0 -2px;
    }
}
.resend-btn:before {
    background-image: url(../images/resend-icon.svg);
}
.mark-paid-btn:before {
    background-image: url(../images/mark-paid-icon.svg);
}
.make-void-btn:before {
    background-image: url(../images/make-void-icon.svg);
}
.download-btn:before {
    background-image: url(../images/export-icon.svg);
}
.edit-btn:before {
    background-image: url(../images/edit-icon.svg);
}
.delete-btn {
    color: #9E0909;

    &:before {
        background-image: url(../images/delete-icon-red.svg);
    }
}
.add-btn {
    display: inline-flex;
    align-items: center;
    column-gap: 4px;
    font-size: $fs-small;

    &:before {
        content: '';
        width: 12px;
        min-width: 12px;
        height: 12px;
        background: url(../images/add-icon-blue.svg) center no-repeat;
        background-size: contain;
        margin: 0 0 -2px;
    }
}
.transactions-link {
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
    font-size: $fs-medium;
    font-weight: 700;

    &:before {
        content: '';
        width: 16px;
        min-width: 16px;
        height: 16px;
        background: url(../images/menu-transactions-icon.svg) center no-repeat;
        background-size: contain;
        margin: 0 0 -4px;
    }
}
.remove-btn {
    font-size: $fs-small;
    font-weight: 500;
    color: $red-color03;
    background: none;
    padding: 0;
}
.share-btn {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: $fs-small;

    &:before {
        content: '';
        width: 12px;
        min-width: 12px;
        height: 12px;
        background: url(../images/share-icon-blue.svg) center no-repeat;
        background-size: contain;
        margin: 0 0 -2px;
    }
}
.approve-decline-actions {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.approve-btn,
.decline-btn {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: 500;
    color: #0F6A2E;
    background: none;
    padding: 0;
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        width: 16px;
        min-width: 16px;
        height: 16px;
        background-image: url(../images/approve-icon.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 0 -2px;
    }
}
.decline-btn {
    color: #BF0F0A;

    &:before {
        background-image: url(../images/decline-icon.svg);
    }
}
/*	   end  buttons   	*/

/*	   progress  indicator   	*/
.progress-indicator {
    max-width: 605px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: $fs-small;
    white-space: nowrap;
    color: $gray-color-main;
    position: relative;
    padding: 0 0 30px;
    margin: 0 auto 40px;

    &:before {
        content: '';
        height: 4px;
        background-color: $progress-indicator-color;
        box-shadow: $shadow01;
        position: absolute;
        top: 7px;
        right: 7px;
        left: 7px;
        z-index: -1;
    }
    li {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $body-bg;
        border: 3px solid $progress-indicator-color;
        border-radius: 50%;
        box-sizing: border-box;
        position: relative;

        &:after {
            content: '';
            width: 200px;
            display: none;
            background-color: $link-color;
            height: 4px;
            position: absolute;
            top: 4px;
            right: 0;
            z-index: -1;
        }
        &:first-child:after {
            display: none !important;
        }
        &.active {
            font-weight: 700;
            color: $text-color;
            border-color: $link-color;

            &:before {
                content: '';
                width: 10px;
                height: 10px;
                background-color: $link-color;
                border-radius: 50%;
            }
            &:after {
                display: block;
            }
        }
        &.completed {
            background: $link-color url(../images/check-icon.svg) center no-repeat;
            background-size: 11px 8px;
            border: none;

            &:after {
                display: block;
                top: 7px;
            }
        }
    }
    .mobile-item {
        width: auto;
        height: auto;
        display: none;
        font-weight: 700;
        color: $text-color;
        border: none;
        position: absolute;
        top: -42px;
        left: 50%;
        transform: translateX(-50%);
    }
    .item-text {
        display: block;
        position: absolute;
        top: calc(100% + 12px);
        left: 50%;
        transform: translateX(-50%);
    }
    &.two-items {
        max-width: 260px;

        li::after {
            width: 240px;
        }
    }
    &.three-items {
        max-width: 410px;

        li::after {
            width: 200px;
        }
    }
    &.six-items {
        max-width: 670px;

        li::after {
            width: 130px;
        }
    }
}
/*	   end  progress  indicator   	*/

/*	   radio buttons,  checkboxes,  toggle   	*/
.radio-buttons {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    font-size: 13px;

    li {
        max-width: 212px;
    }
    input {
        display: none;

        &:checked + label {
            background-color: $light-blue-bg02;
            border-color: $link-color;

            &:after {
                opacity: 1;
            }
        }
        &[disabled] + label {
            opacity: .5;
            pointer-events: none;
        }
    }
    label {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #515D62;
        text-align: center;
        background-color: $body-bg;
        border: 1px solid #DFE7EA;
        border-radius: 4px;
        padding: 0 25px;
        box-sizing: border-box;
        cursor: pointer;
        transition: all .1s ease-in-out;
        position: relative;

        &:hover {
            background-color: $light-blue-bg02;
        }
        &:after {
            content: '';
            width: 15px;
            height: 15px;
            background: $link-color url(../images/check-icon.svg) center no-repeat;
            background-size: 8px 6px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            opacity: 0;
            transition: opacity .1s ease-in-out;
        }
    }
}
.radio-list,
.checkbox-list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    font-size: $fs-small;
    font-weight: normal;

    input {
        display: none;

        &:checked + label:before {
            border: 2px solid $link-color;
        }
    }
    label {
        display: block;
        position: relative;
        padding-left: 18px;
        cursor: pointer;

        &:before {
            content: '';
            width: 10px;
            height: 10px;
            border: 1px solid #414A50;
            border-radius: 50%;
            box-sizing: border-box;
            position: absolute;
            top: 6px;
            left: 0;
            transition: all .1s ease-in-out;
        }
    }
}
.checkbox-list {

    input:checked + label:before {
        background-color: $link-color;
        border-width: 1px;
    }
    label {

        &:before {
            border-radius: 2px;
        }
        &:after {
            content: '';
            width: 4px;
            height: 4px;
            background: url(../images/checkbox-icon.svg) center no-repeat;
            background-size: contain;
            position: absolute;
            top: 9px;
            left: 3px;
        }
    }
}
.single-checkbox {
    width: 10px;
    height: 10px;

    input {
        display: none;

        &:checked + label {
            background-color: $link-color;
            border-color: $link-color;
        }
    }
    label {
        display: block;
        height: 100%;
        border: 1px solid #979797;
        border-radius: 2px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        z-index: 1;
        transition: all .1s ease-in-out;

        &:after {
            content: '';
            width: 7px;
            height: 4px;
            background: url(../images/checkbox-icon.svg) center no-repeat;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
.toggle-hold {
    width: 24px;
    min-width: 24px;
    height: 24px;

    input {
        display: none;

        &:checked + label:after {
            left: 11px;
        }
    }
    label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;

        &:before {
            content: '';
            width: 22px;
            height: 13px;
            background-color: $link-color;
            border-radius: 7px;
        }
        &:after {
            content: '';
            width: 11px;
            height: 11px;
            background-color: $body-bg;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 2px;
            transform: translateY(-50%);
            transition: left .2s ease-in-out;
        }
    }
}
/*	   end radio buttons,  checkboxes,  toggle   	*/

/*	   table   	*/
.table-hold {
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 8px;

    .absent-hold {
        min-height: 340px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .medium-title {
            font-size: $fs-bold;
        }
    }
    &.with-actions {
        padding: 0 0 180px;

        table tr:last-child .table-actions .dropdown-box {
            top: 100%;
            bottom: auto;
        }
    }
    + .strong-title {
        padding-top: 50px;
    }
}
.table-header {
    min-height: 66px;
    display: flex;
    align-items: center;
    column-gap: 20px;
    white-space: normal;
    color: $text-color;
    background-color: $body-bg;
    padding: 13px 32px;
    box-sizing: border-box;
    margin: -10px -40px;

    &.blue-bg {
        background-color: $light-blue-bg01;
    }
    .search-hold {
        max-width: 340px;

        input[type="text"] {
            border: 1px solid $border-color05;
            box-shadow: none;
        }
    }
    .export-hold {
        position: relative;
        margin: 0 0 0 auto;

        &.open .dropdown-box {
            opacity: 1;
            visibility: visible;
        }
        .export-button {
            margin: 0;
        }
        .dropdown-box {
            width: 230px;
            top: 100%;
            right: 0;
        }
    }
    .medium-title,
    .strong-title {
        margin: 0;
    }
    .mark-btn,
    .unmark-btn {
        font-size: $fs-small;
        font-weight: 500;
        color: $link-color;
        background: none;
        padding: 0;
    }
    .unmark-btn {
        color: $red-color01;
    }
    .invoice-actions,
    .team-actions,
    .spenders-actions {
        display: flex;
        align-items: center;
        column-gap: 20px;
        margin: 0 0 0 auto;
    }
    .team-actions,
    .spenders-actions {
        column-gap: 35px;

        .basic-button {
            margin: 0;
        }
    }
    .view-all-link {
        margin: 0 0 0 auto;
    }
}
table {
    width: 100%;
    white-space: nowrap;
    border-spacing: 0;
    font-weight: 500;
    color: $gray-color02;
    background-color: $body-bg;

    tr {
        position: relative;
        transition: background-color .1s ease-in-out;

        &:hover {
            background-color: $light-gray-bg;
        }
        &:last-child {

            .table-actions .dropdown-box {
                top: auto;
                bottom: 100%;
            }
        }
    }
    th,
    td {
        height: 56px;
        border-bottom: 1px solid $border-color04;
        padding: 10px 20px;
        box-sizing: border-box;

        &:first-of-type {
            padding-left: 40px;
        }
        &:last-of-type {
            padding-right: 40px;
        }
    }
    th {
        font-size: $fs-medium;
        font-weight: 700;
        text-align: left;
        background-color: #FCFCFC;
    }
    .table-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;

        &.open .dropdown-box {
            opacity: 1;
            visibility: visible;
        }
        .dropdown-box {
            width: 120px;
            top: 100%;
            right: 0;
        }
        ul li > button {
            font-size: $fs-small;
            font-weight: 500;
            color: $text-color;
            background: none;
            padding: 0;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .actions-btn {
        width: 16px;
        height: 16px;
        background: url(../images/actions-icon.svg) center no-repeat;
        background-size: contain;
        padding: 0;
    }
    .open-details.open {

        .details-box {
            right: 0;
        }
        .basic-overlay {
            opacity: 1;
            visibility: visible;
        }
    }
    .hidden-cell {
        display: none;
    }
    &.with-checkbox td:first-of-type {
        width: 10px;
        padding: 10px 0 10px 20px;
    }
    .invitation-pending {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 4px 5px;
    }
    .details-btn-cell {
        padding: 0;

        .details-btn-hold {
            height: 100%;
            position: relative;
        }
        .details-btn {
            width: 9999px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }
}
/*	   end  table   	*/

/*	   statuses   	*/
.status {
    height: 18px;
    display: inline-flex;
    align-items: center;
    font-size: $fs-minimal;
    border-radius: 4px;
    padding: 0 8px;
    
    &.pending,
    &.active,
    &.owner,
    &.awaiting-approval {
        color: #8A6C03;
        background-color: #FBF5E5;
    }
    &.completed,
    &.paid,
    &.connected {
        color: #038A39;
        background-color: #E7FBE5;
    }
    &.overdue,
    &.failed {
        color: #8A0303;
        background-color: #FBE5E5;
    }
    &.inflow {
        color: #2E3A31;
        background-color: #F2F2F2;
    }
    &.processing {
        color: #D4AE25;
        background-color: #FFF7E6;
    }
    &.void,
    &.draft {
        color: #636056;
        background-color: #EEEEEE;
    }
    &.contractor,
    &.supplier {
        color: #6B6B6B;
        background-color: $gray-bg;
    }
    &.coming-soon {
        color: #454545;
        background-color: #EFEFEF;
        border-radius: 0 0 0 14px;
        padding: 0 12px;
        position: absolute;
        top: 0;
        right: 0;
    }
    &.accountant {
        color: #5F038A;
        background-color: #F5E5FB;
    }
}
/*	   end  statuses   	*/

/*	   tabs   	*/
.tab {

    &.hidden {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    &.visible {
        position: static;
    }
}
.basic-tabs {
    padding: 0 0 48px;
}
.tabs-menu {
    display: flex;
    column-gap: 40px;
    font-size: $fs-medium;
    font-weight: 500;
    color: $gray-color04;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: 1px solid $border-color02;
    margin: 0 0 20px;

    li {
        border-bottom: 4px solid transparent;
        padding-bottom: 10px;
        box-sizing: border-box;
        margin: 0 0 -1px;
        transition: all .1s ease-in-out;
        cursor: pointer;

        &:hover {
            color: $link-color;
        }
        &.active {
            font-weight: 700;
            color: $link-color;
            border-bottom-color: $link-color;
            cursor: default;
        }
    }
}
/*	   end  tabs   	*/

/*	   filter   	*/
.filter-box {
    color: #414A50;
    position: relative;

    &.open {

        .filter-drop,
        .filter-inflow {
            opacity: 1;
            visibility: visible;
        }
    }
    .basic-button {
        margin: 0;
    }
}
.filter-drop {
    width: 190px;
    padding: 16px;
    top: calc(100% + 10px);

    .basic-button {
        width: 100%;
    }
}
.filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    margin: 0 0 10px;

    .strong-title {
        margin: 0;
    }
    > a {
        font-size: $fs-small;
        margin: 0 0 -2px;
    }
}
.filter-list {
    margin: 0 0 35px;

    > li {
        position: relative;

        &.open .sub-list {
            opacity: 1;
            visibility: visible;
        }
    }
    .filter-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        position: relative;
        cursor: pointer;

        &:after {
            content: '';
            width: 4px;
            min-width: 4px;
            height: 8px;
            background: url(../images/arrow-right-icon-black.svg) center no-repeat;
            background-size: contain;
            margin: 0 0 -2px;
        }
    }
    .sub-list {
        width: 150px;
        background-color: $body-bg;
        box-shadow: $shadow01;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: calc(100% + 16px);
        padding: 16px;
        box-sizing: border-box;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease-in-out;
    }
}
.filter-inflow {
    width: 160px;
    top: calc(100% + 10px);
}
/*	   end  filter   	*/

/*	   pagination   	*/
.pagination {
    display: flex;
    align-items: center;
    column-gap: 6px;
    margin-top: auto;

    .pagination-items {
        font-weight: 500;
        color: #6A6D71;
    }
    .pagination-arrows {
        display: flex;
        align-items: center;
        column-gap: 10px;

        > a {
            width: 16px;
            min-width: 16px;
            height: 16px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../images/chevron-right-icon.svg);
            background-size: contain;

            &.disabled {
                pointer-events: none;
                background-image: url(../images/chevron-right-icon-gray.svg);
            }
        }
        .prev {
            transform: scaleX(-1);
        }
    }
    select {
        height: 40px;
        font-weight: 500;
        color: $text-color;
        background-color: $body-bg;
        padding: 0 36px 0 10px;
    }
    .select-field:after {
        width: 16px;
        height: 16px;
        background: url(../images/chevron-right-icon.svg) center no-repeat;
        background-size: contain;
        transform: rotate(90deg);
        top: 15px;
        right: 10px;
    }
}
/*	   end  pagination   	*/

/*	   toast  notification   	*/
.toast-notification {
    width: 320px;
    max-width: calc(100% - 88px);
    color: $green-color;
    background-color: #E2FFEA;
    border-radius: 0 8px 8px 0;
    position: fixed;
    top: 100px;
    right: 100px;
    z-index: 2;

    &:before {
        content: '';
        width: 40px;
        height: 40px;
        background-color: $green-color;
        background-image: url(../images/notification-success-icon.svg);
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px 0 0 8px;
        position: absolute;
        top: 0;
        right: 100%;
    }
    .title-notification {
        display: block;
        line-height: 20px;
        font-weight: 500;
        padding: 10px 25px 10px 16px;
    }
    .close-notification {
        width: 10px;
        height: 10px;
        background-color: transparent;
        background-image: url(../images/close-icon-green.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 0;
        position: absolute;
        top: 15px;
        right: 15px;
    }
}
.failed-notification {
    color: $red-color04;
    background-color: #FFE2E2;

    &:before {
        background-color: $red-color04;
        background-image: url(../images/notification-failed-icon.svg);
    }
    .close-notification {
        background-image: url(../images/close-icon-red.svg);
    }
    small {
        padding: 0 16px 10px 16px;
    }
}
/*	   end  toast  notification   	*/